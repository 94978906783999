import React, { Component } from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import routes from "./routes";
import PrivateRoute from "./routes/Private";
import PublicRoute from "./routes/Public";
import MarketPlaceRoute from "./routes/MarketPlace";

import NotFound from "./components/notFound";

// Import scss
import "./theme.scss";

class App extends Component {
  checkDomainIsMarketPlace = () => {
    const hostName = window.location.hostname;
    const marketPlacedomains = [
      "marketplace.getmee.ai",
      "stagemarketplace.getmee.ai",
    ];
    return marketPlacedomains.includes(hostName);
  };

  render() {
    return (
      <React.Fragment>
        <Switch>
          {routes.map((route, i) => {
            if (route.marketplace) {
              return <MarketPlaceRoute key={i} {...route} />;
            }
            if (route.auth) {
              return <PrivateRoute key={i} {...route} />;
            }
            return <PublicRoute key={i} {...route} />;
          })}
          <Route path="/404" component={NotFound} />
          {this.checkDomainIsMarketPlace() ? (
            <React.Fragment>
              <Redirect path="/" exact to="/marketplace/home" />
              <Redirect to="/marketplace/home" />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Redirect path="/" exact to="/enterprise/dashboard" />
              <Redirect to="/login" />
            </React.Fragment>
          )}
        </Switch>
      </React.Fragment>
    );
  }
}

export default App;
