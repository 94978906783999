// Actions
const SEARCH_CHANGE = "SEARCH_CHANGE";
const UPDATE_GROUPS = "UPDATE_GROUPS";
const TOGGLE_GROUP_LOADER = "TOGGLE_GROUP_LOADER";

// Action Creators
export function triggerSearchChange(payload) {
  return { type: SEARCH_CHANGE, payload };
}

export function triggerUpdateGroups(payload) {
  return { type: UPDATE_GROUPS, payload };
}

export function toggleGroupLoader() {
  return { type: TOGGLE_GROUP_LOADER };
}

const initialState = {
  searchQuery: "",
  currentPage: 1,
  totalPages: 1,
  groups: [],
  loading: false,
  hasMoreGroups: false, // to track if there are more groups to load
};

// Reducer
export default function reducer(
  state = initialState,
  { type, payload = null }
) {
  switch (type) {
    case SEARCH_CHANGE:
      return handleSearchChange(state, payload);
      case UPDATE_GROUPS:
      return handleupdateGroups(state, payload);
      case TOGGLE_GROUP_LOADER:
      return handleToggleLoader(state);
    default:
      return state;
  }
}

function handleSearchChange(state, payload) {
  return {
    ...state,
    loading: true,
    currentPage: 1,
    totalPages: 1,
    groups: [],
    searchQuery: payload,
    hasMoreGroups: false,
  };
}

function handleupdateGroups(state, payload) {
  return {
    ...state,
    currentPage: payload.currentPage,
    loading: false,
    groups: [...state.groups, ...payload.listdata],
    totalPages: payload.totalPages,
    hasMoreGroups: payload.currentPage < payload.totalPages,
  };
}

function handleToggleLoader(state) {
  return {
    ...state,
    loading: !state.loading,
  };
}