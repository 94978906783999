// import lib
import { lazy } from "react";

const enterPriseRoutes = [
  {
    path: "/login",
    exact: true,
    routefor: "enterprise",
    component: lazy(() =>
      import(
        /* webpackChunkName: "ed-login" */ "./../components/enterprise/auth/login"
      )
    ),
  },
  {
    path: "/forgot-password",
    exact: true,
    routefor: "enterprise",
    component: lazy(() =>
      import(
        /* webpackChunkName: "ed-forgot-password" */ "./../components/enterprise/auth/forgotpassword"
      )
    ),
  },
  {
    path: "/password/reset/:token",
    exact: true,
    routefor: "enterprise",
    component: lazy(() =>
      import(
        /* webpackChunkName: "ed-reset-password" */ "./../components/enterprise/auth/resetpassword"
      )
    ),
  },
  {
    path: "/auth-check",
    exact: true,
    // auth: true,
    routefor: "enterprise",
    component: lazy(() =>
      import(
        /* webpackChunkName: "ed-auth-check" */ "./../components/enterprise/auth/authcheck"
      )
    ),
  },
  {
    path: "/enterprise/unauthorized",
    exact: true,
    auth: true,
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "unauthorized" */ "./../components/enterprise/common/Unauthorized.jsx"
      )
    ),
  },
  {
    path: "/enterprise/dashboard",
    exact: true,
    auth: true,
    permission: "dashboard",
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-dashboard" */ "./../components/enterprise/dashboard"
      )
    ),
  },
  {
    path: "/enterprise/members",
    exact: true,
    auth: true,
    permission: "members",
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-members" */ "./../components/enterprise/members/list"
      )
    ),
  },
  {
    path: "/enterprise/member/detail/:id",
    exact: true,
    auth: true,
    permission: "members",
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-member-details" */ "./../components/enterprise/members/details"
      )
    ),
  },
  {
    path: "/enterprise/tasks-library",
    exact: true,
    auth: true,
    permission: "task-library",
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-taskLibrary" */ "./../components/enterprise/taskLibrary/list"
      )
    ),
  },
  {
    path: "/enterprise/tasks-library-new",
    exact: true,
    auth: true,
    permission: "task-library",
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-taskLibrary-new" */ "./../components/enterprise/taskLibrary/listnew"
      )
    ),
  },
  {
    path: "/enterprise/create-task",
    exact: true,
    auth: true,
    permission: "task-library",
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-createtask" */ "./../components/enterprise/taskLibrary/create"
      )
    ),
  },
  {
    path: "/enterprise/tasks-library/edit/:id",
    exact: true,
    auth: true,
    permission: "task-library",
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-edittask" */ "./../components/enterprise/taskLibrary/edit"
      )
    ),
  },
  {
    path: "/enterprise/bulk-assign-task",
    exact: true,
    auth: true,
    permission: "task-library",
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-bulkAssignTask" */ "./../components/enterprise/taskLibrary/bulkAssignTask"
      )
    ),
  },
  {
    path: "/enterprise/tasks-library/view/:id",
    exact: true,
    auth: true,
    permission: "task-library",
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-viewtask" */ "./../components/enterprise/taskLibrary/view"
      )
    ),
  },
  {
    path: "/enterprise/my-tasks",
    exact: true,
    auth: true,
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-mytask" */ "./../components/enterprise/taskLibrary/myTasksList"
      )
    ),
  },
  {
    path: "/enterprise/my-tasks/view/:id",
    exact: true,
    auth: true,
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-viewmytask" */ "./../components/enterprise/taskLibrary/myTaskView"
      )
    ),
  },
  {
    path: "/enterprise/member-task/view/:id",
    exact: true,
    auth: true,
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-viewmembertask" */ "./../components/enterprise/taskLibrary/myTaskView"
      )
    ),
  },
  {
    path: "/enterprise/content-library",
    exact: true,
    auth: true,
    permission: "content-library",
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-contentLibrary" */ "./../components/enterprise/contentLibrary/list"
      )
    ),
  },
  {
    path: "/enterprise/courses",
    exact: true,
    auth: true,
    // permission: "content-library",
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-coursesList" */ "./../components/enterprise/courses/list"
      )
    ),
  },
  {
    path: "/enterprise/create-courses",
    exact: true,
    auth: true,
    // permission: "task-library",
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-createCourses" */ "./../components/enterprise/courses/create"
      )
    ),
  },
  {
    path: "/enterprise/course/edit/:id",
    exact: true,
    auth: true,
    // permission: "task-library",
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-editCourse" */ "./../components/enterprise/courses/edit"
      )
    ),
  },
  {
    path: "/enterprise/course-enrollments/:id",
    exact: true,
    auth: true,
    // permission: "content-library",
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-coursesEnrollments" */ "./../components/enterprise/courses/enrollmentsList"
      )
    ),
  },
  {
    path: "/enterprise/reports",
    exact: true,
    auth: true,
    permission: "reports",
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-reports" */ "./../components/enterprise/reports"
      )
    ),
  },
  {
    path: "/enterprise/profile",
    exact: true,
    auth: true,
    routefor: "enterprise",
    layout: "fullpage",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-profile" */ "../components/enterprise/auth/profile"
      )
    ),
  },
  {
    path: "/enterprise/roles",
    exact: true,
    auth: true,
    permission: "roles",
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-roles" */ "./../components/enterprise/roles/list"
      )
    ),
  },
  {
    path: "/enterprise/help",
    exact: true,
    auth: true,
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-help" */ "./../components/enterprise/help"
      )
    ),
  },
  {
    path: "/enterprise/settings",
    exact: true,
    auth: true,
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-settings" */ "./../components/enterprise/settings"
      )
    ),
  },
  {
    path: "/enterprise/reading-topics",
    exact: true,
    auth: true,
    permission: "reading-topics",
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-readingTopics" */ "./../components/enterprise/readingTopics/list"
      )
    ),
  },
  {
    path: "/enterprise/groups",
    exact: true,
    auth: true,
    permission: "groups",
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-groups" */ "./../components/enterprise/groups/list"
      )
    ),
  },
  {
    path: "/enterprise/groups/manage/:id?",
    exact: true,
    auth: true,
    permission: "groups",
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-addEditGroup" */ "./../components/enterprise/groups/addEditGroup"
      )
    ),
  },
  {
    path: "/enterprise/groups/detail/:id",
    exact: true,
    auth: true,
    permission: "groups",
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-groups-details" */ "./../components/enterprise/groups/details"
      )
    ),
  },
  {
    path: "/enterprise/company-info",
    exact: true,
    auth: true,
    // permission: "content-library",
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-companyInfo" */ "./../components/enterprise/companyInfo"
      )
    ),
  },
  {
    path: "/enterprise/config/pronunciation",
    exact: true,
    auth: true,
    permission: "groups",
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-config-pronunciation" */ "./../components/enterprise/configurations/pronunciation"
      )
    ),
  },
  {
    path: "/enterprise/surveys",
    exact: true,
    auth: true,
    permission: "surveys",
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-surveys" */ "./../components/enterprise/surveys"
      )
    ),
  },
  {
    path: "/enterprise/surveys/manage/:id?",
    exact: true,
    auth: true,
    permission: "surveys",
    routefor: "enterprise",
    layout: "sidebar",
    component: lazy(() =>
      import(
        /* webpackChunkName: "enterprise-addEditSurvey" */ "./../components/enterprise/surveys/addEditSurvey"
      )
    ),
  },
];

export default enterPriseRoutes;
