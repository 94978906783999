import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import COMMON_EN from './_locales/en/common.json'
import NAVIGATION_EN from './_locales/en/navigation.json'
import AUTH_EN from './_locales/en/auth.json'
import DASHBOARD_EN from './_locales/en/dashboard.json'
import MEMBERS_EN from './_locales/en/members.json'
import TASKS_EN from './_locales/en/tasks.json'

i18n.use(initReactI18next).init({
  debug: process.env.REACT_APP_ENV !== 'prod',
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      common: COMMON_EN,
      navigation: NAVIGATION_EN,
      auth: AUTH_EN,
      dashboard: DASHBOARD_EN,
      members: MEMBERS_EN,
      tasks: TASKS_EN,
    },
  },
})

export default i18n
