import { combineReducers } from "redux";
import auth from "./auth";
import generalActions from "./generalActions";
import marketplace from "./marketplace";
import globalGroup from "./globalGroup";
import { reducer as toastrReducer } from "react-redux-toastr";

export default combineReducers({
  toastr: toastrReducer,
  auth,
  generalActions,
  marketplace,
  globalGroup,
});
