import React, { Suspense } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import LoaderCircle from "../components/common/loader-circle";
import AdminLayout from "./../components/admin/layout";
import EnterpriseLayout from "./../components/enterprise/layout";
import jwt_decode from "jwt-decode";
import { authRefreshToken } from "./../components/admin/adminServices";
import { adminauthLogin, adminAuthRefreshToken } from "./../store/reducers/auth";

const checkTokenAndRefresh = async (dispatch, isAdminRefreshDone) => {
  let token = sessionStorage.getItem("admin_access_token");
  if (!!token) {
    let decoded = jwt_decode(token);
    const currentTime = Math.floor(Date.now() / 1000);
    const timeLapsed = decoded.exp - currentTime;
    if (timeLapsed > 0 && timeLapsed <= 72000) {
      // that means token time is less than refresh it
      if (!isAdminRefreshDone) {
        dispatch(adminAuthRefreshToken());
        try {
          const { data: res } = await authRefreshToken();
          if (res.success == true) {
            // notifySuccess(res.message);
            dispatch(adminauthLogin(res.response.accesstoken));
          }
        } catch (err) {
          console.log(err);
        }
        dispatch(adminAuthRefreshToken());
      }
    }
  }
};

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  isMFAAuthenticated,
  isAdminAuthenticated,
  isMFAAdminAuthenticated,
  isAdminRefreshDone,
  userPermissions,
  refreshing,
  routefor,
  layout,
  dispatch,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (routefor == "admin") {
          if (!isAdminAuthenticated) {
            return (
              <Redirect
                to={{
                  pathname: "/admin/login",
                  state: { from: props.location },
                }}
              />
            );
          }
          if (isAdminAuthenticated && !isMFAAdminAuthenticated) {
            return (
              <Redirect
                to={{
                  pathname: "/admin/auth-check",
                  state: { from: props.location },
                }}
              />
            );
          }
          // check if token is expired or not
          checkTokenAndRefresh(dispatch, isAdminRefreshDone);
          return (
            <Suspense fallback={<div>Loading...</div>}>
              <LoaderCircle isLoading={refreshing} />
              {isAdminAuthenticated ? (
                layout == "sidebar" ? (
                  <React.Fragment>
                    <AdminLayout>
                      <Component {...props} />
                    </AdminLayout>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Component {...props} />
                  </React.Fragment>
                )
              ) : (
                <Redirect
                  to={{
                    pathname: "/admin/login",
                    state: { from: props.location },
                  }}
                />
              )}
            </Suspense>
          );
        }
        const restProp = { ...rest };
        if (
          isAuthenticated &&
          isMFAAuthenticated &&
          restProp.permission !== undefined &&
          !_.isEmpty(userPermissions) &&
          !userPermissions.includes(restProp.permission)
        ) {
          return (
            <Redirect
              to={{
                pathname: "/enterprise/unauthorized",
                state: { from: props.location },
              }}
            />
          );
        }
        if (!isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }
        if (isAuthenticated && !isMFAAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: "/auth-check",
                state: { from: props.location },
              }}
            />
          );
        }
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <LoaderCircle isLoading={refreshing} />
            {isAuthenticated && isMFAAuthenticated ? (
              layout == "sidebar" ? (
                <React.Fragment>
                  <EnterpriseLayout>
                    <Component {...props} />
                  </EnterpriseLayout>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Component {...props} />
                </React.Fragment>
              )
            ) : (
              <></>
            )}
          </Suspense>
        );
      }}
    />
  );
};

PrivateRoute.displayName = "Private Routes";

PrivateRoute.propTypes = {
  component: PropTypes.object.isRequired,
  location: PropTypes.object,
  isAuthenticated: PropTypes.bool.isRequired,
  isAdminAuthenticated: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

// Retrieve data from store as props
function mapStateToProps(store) {
  return {
    isAuthenticated: store.auth.isAuthenticated,
    isMFAAuthenticated: store.auth.isMFAAuthenticated,
    isAdminAuthenticated: store.auth.isAdminAuthenticated,
    isMFAAdminAuthenticated: store.auth.isMFAAdminAuthenticated,
    isAdminRefreshDone: store.auth.isAdminRefreshDone,
    refreshing: store.generalActions.refreshing,
    userPermissions: store.auth.user.roleId.permissions,
  };
}

export default withRouter(connect(mapStateToProps)(PrivateRoute));
