import Http from "./../../utils/Http";
import { authLogout, authUser } from "./../../store/reducers/auth";
import { triggerUpdateGroups } from "./../../store/reducers/globalGroup";
import { notifyError } from "./../../utils/Helper";

const apiBase = "/api/v1/enterprise/";

/**
 * login user
 *
 * @param credentials
 * @returns {function(*)}
 */
export function login(credentials) {
  return Http.post("login", credentials);
}

export function verifyEmail(params) {
  return Http.post("verify-email", params);
}

/**
 * logout user
 *
 * @returns {function(*)}
 */
export function logout() {
  return (dispatch) => {
    dispatch(authLogout());
    // return Http.delete("auth/logout")
    //   .then(() => {
    //     dispatch(authLogout());
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
}

export function fetchUser() {
  return (dispatch) => {
    return Http.get(apiBase + "general/auth/me")
      .then((res) => {
        const data = res.data.response;
        dispatch(authUser(data));
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          const { data } = err.response;
          notifyError(data.message);
          dispatch(authLogout());
        }
      });
  };
}

export function fetchGroups(params) {
  return (dispatch) => {
    return Http.post(apiBase + "general/user-groups", params)
      .then((res) => {
        const data = res.data.response;
        dispatch(triggerUpdateGroups(data));
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          const { data } = err.response;
          notifyError(data.message);
          dispatch(authLogout());
        }
      });
  };
}

export function updateProfile(params) {
  return Http.post(apiBase + "general/auth/updateprofile", params);
}

export function forgotPasswordEmail(params) {
  return Http.post("forgotpassword", params);
}

export function getMFADetails(params) {
  return Http.post(apiBase + "mfa/auth/get-details", params);
}

export function sendMFACode(params) {
  return Http.post(apiBase + "mfa/auth/send-code", params);
}

export function authCheckMFA(params) {
  return Http.post(apiBase + "mfa/auth/verify-code", params);
}

export function resetPassword(params) {
  return Http.post("resetpassword", params);
}

export function checkResetPasswordToken(params) {
  return Http.post("check-reset-token", params);
}

export function updateCompanyInfo(params) {
  return Http.post(apiBase + "general/auth/updatecompanyinfo", params);
}

export function changePassword(params) {
  return Http.post(apiBase + "general/auth/changepassword", params);
}

export function updateProfileImageRequest(params) {
  return Http.post(apiBase + "general/auth/updateprofileimage", params);
}

export function updateThemeSettings(params) {
  return Http.post(apiBase + "general/auth/updatethemesettings", params);
}

export function sendHelpMessage(params) {
  return Http.post(apiBase + "general/help-email", params);
}

export function demoVideoViewed() {
  return (dispatch) => {
    return Http.post(apiBase + "general/auth/demo-video-viewed")
      .then((res) => {
        const data = res.data.response;
        dispatch(authUser(data));
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          const { data } = err.response;
          notifyError(data.message);
          dispatch(authLogout());
        }
      });
  };
}

export function putSignedUrlS3(params) {
  return Http.post(
    apiBase + "general/put-signed-request-for-upload-to-s3",
    params
  );
}

export function putSignedUrlS3ForTask(params) {
  return Http.post(
    apiBase + "general/put-signed-request-for-upload-to-s3-task",
    params
  );
}

export function getEnterpriseDashboardData(params) {
  const queryParams = new URLSearchParams(params).toString();
  return Http.get(apiBase + "general/my-dashboard?" + queryParams);
}

export function getCompletedTasksPendingReviewsList(params) {
  // console.log(">>> params ", params);
  let {
    url = apiBase + "tasks/get-completed-tasks-pending-review",
    searchInput = "",
    currentPage = 1,
    pageLimit = 10,
    sortBy = "name",
    sortType = "asc",
    selectedGroup = "",
  } = params;
  if (searchInput != "") {
    let currentPage = 1;
  }
  let _getParams = [];
  if (currentPage > 1) {
    _getParams.push(`currentPage=${currentPage}`);
  }
  _getParams.push(`limit=${pageLimit}`);
  _getParams.push(`sort_by=${sortBy}`);
  _getParams.push(`sort_type=${sortType}`);
  _getParams.push(`selected_group=${selectedGroup}`);
  if (searchInput != "") {
    _getParams.push(`searchInput=${searchInput}`);
  }
  url = url + "?" + _getParams.join("&");
  return Http.get(url);
}

export function addTaskFeedback(params) {
  return Http.post(apiBase + "tasks/add-task-feedback", params);
}

export function getEnterpriseTasks(params) {
  let { url = apiBase + "tasks/get-enterprise-tasks", searchInput = "", currentPage = 1, pageLimit = 25, } = params;
  let _getParams = [];
  if (searchInput != "") {
    _getParams.push(`searchInput=${searchInput}`);
  }
  if (currentPage > 1) {
    _getParams.push(`currentPage=${currentPage}`);
  }
  _getParams.push(`limit=${pageLimit}`);
  url = url + "?" + _getParams.join("&");
  return Http.get(url);
}

export function assignTaskToFolder(params) {
  return Http.post(apiBase + "task-folders/assign-task-folder", params);
}