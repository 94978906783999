import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { Link } from "react-router-dom";
import { updateSelectedGroup } from "./../../../store/reducers/auth";
import {
  toggleGroupLoader,
  triggerSearchChange,
} from "./../../../store/reducers/globalGroup";
import ProfileMenu from "./ProfileMenu";
import { fetchGroups } from "./../enterpriseServices";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownGroupOpen: false,
      localSearchQuery: "",
      typingTimeout: 0,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleGroupDropdown = this.toggleGroupDropdown.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }

  componentDidMount() {
    if (this.props.globalGroup.searchQuery != "") {
      this.setState({
        localSearchQuery: this.props.globalGroup.searchQuery,
      });
    }
  }

  toggleGroupDropdown() {
    this.setState((prevState) => ({
      isDropdownGroupOpen: !prevState.isDropdownGroupOpen,
    }));
  }

  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.toggleMenuCallback();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  handleGroupChange = (e, group) => {
    e.preventDefault();
    this.props.dispatch(updateSelectedGroup(group));
  };

  handleGroupChangeBlank = (e) => {
    e.preventDefault();
    this.setState(
      {
        localSearchQuery: "",
      },
      () => {
        this.props.dispatch(triggerSearchChange(""));
        this.loadGroups();
      }
    );

    this.props.dispatch(updateSelectedGroup({ _id: "", name: "" }));
  };

  handleSearchChange = (e) => {
    const val = e.target.value;

    // Clear any existing timeout
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    // Set a new timeout to delay the search
    this.setState({
      localSearchQuery: val,
      typingTimeout: setTimeout(() => {
        this.props.dispatch(triggerSearchChange(this.state.localSearchQuery));
        // Call your API to fetch groups based on searchQuery and currentPage
        this.props.dispatch(
          fetchGroups({
            searchQuery: this.state.localSearchQuery,
            currentPage: 1,
          })
        );
      }, 500), // 500ms delay before search is triggered
    });
  };

  loadGroups = () => {
    const { searchQuery } = this.state;
    this.props.dispatch(toggleGroupLoader());

    // Call your API to fetch groups based on searchQuery and currentPage
    this.props.dispatch(fetchGroups({ searchQuery, currentPage: 1 }));
  };

  handleScroll = (e) => {
    // console.log('here');
    const { hasMoreGroups, loading, searchQuery, currentPage } =
      this.props.globalGroup;

    if (loading || !hasMoreGroups) return;

    const element = e.target;
    const bottom =
      element.scrollHeight - element.scrollTop <= element.clientHeight + 1;

    // const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      this.props.dispatch(toggleGroupLoader());
      this.props.dispatch(
        fetchGroups({ searchQuery, currentPage: currentPage + 1 })
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header navbar-header-padding">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/enterprise/dashboard" className="logo logo-dark">
                  <span className="logo-sm">
                    <img
                      src={this.props.user.enterpriseId.enterprise_logo}
                      alt={this.props.user.enterpriseId.company_name}
                      height="22"
                    />
                  </span>
                  <span className="logo-lg">
                    <img
                      src={this.props.user.enterpriseId.enterprise_logo}
                      alt={this.props.user.enterpriseId.company_name}
                      height="60px"
                    />
                  </span>
                </Link>
              </div>

              {this.props.showMenuToggle && (
                <Button
                  size="sm"
                  color="none"
                  type="button"
                  onClick={this.toggleMenu}
                  className="px-3 font-size-24 header-item waves-effect"
                  id="vertical-menu-btn"
                >
                  <i className="ri-menu-2-line align-middle"></i>
                </Button>
              )}
            </div>

            <div className="d-flex">
              {this.props.showMenuToggle ? (
                <React.Fragment>
                  {!!this.props.globalGroup.groups && (
                    <ButtonGroup className="mt-3 mr-2 mb-sm-0">
                      <Dropdown
                        isOpen={this.state.isDropdownGroupOpen}
                        toggle={this.toggleGroupDropdown}
                      >
                        <DropdownToggle
                          tag="button"
                          className="btn btn-light waves-light waves-effect dropdown-toggle"
                          data-toggle="dropdown"
                          aria-expanded="false"
                          style={{
                            backgroundColor: "#FFFFFF",
                            borderColor: "#E4E4E4",
                            boxShadow: "0px 3px 6px #00000017",
                          }}
                        >
                          {!_.isEmpty(this.props.selectedGroupVal)
                            ? this.props.selectedGroupVal
                            : "All Groups"}
                          <i className="mdi mdi-chevron-down ml-2"></i>
                        </DropdownToggle>
                        <DropdownMenu
                          style={{
                            maxHeight: "300px",
                            overflowY: "auto",
                            maxWidth: "330px",
                            minWidth: "330px",
                            position: "relative",
                            padding: "0px",
                          }}
                          onScroll={this.handleScroll}
                        >
                          <div
                            style={{
                              position: "sticky",
                              top: 0,
                              backgroundColor: "#fff",
                              zIndex: 1,
                              padding: "0px",
                            }}
                          >
                            <input
                              type="text"
                              placeholder="Search groups..."
                              className="form-control"
                              value={this.state.localSearchQuery}
                              onChange={this.handleSearchChange}
                              style={{ margin: "10px", width: "90%" }}
                            />
                          </div>
                          {!_.isEmpty(this.props.selectedGroupVal) && (
                            <DropdownItem
                              tag="a"
                              href="#"
                              onClick={this.handleGroupChangeBlank}
                            >
                              All Groups
                            </DropdownItem>
                          )}
                          {this.props.globalGroup.groups.map((group) => {
                            return (
                              <DropdownItem
                                tag="a"
                                href="#"
                                key={`group-global-${group._id}`}
                                onClick={(e) =>
                                  this.handleGroupChange(e, group)
                                }
                              >
                                {group.name}
                              </DropdownItem>
                            );
                          })}
                          {this.props.globalGroup.loading && (
                            <DropdownItem disabled>Loading...</DropdownItem>
                          )}
                        </DropdownMenu>
                      </Dropdown>
                    </ButtonGroup>
                  )}
                  <div className="dropdown d-none ml-1">
                    <Button
                      color="none"
                      type="button"
                      className="header-item noti-icon waves-effect"
                      onClick={this.toggleFullscreen}
                    >
                      <i className="ri-fullscreen-line"></i>
                    </Button>
                  </div>
                </React.Fragment>
              ) : (
                <Link
                  className="waves-effect waves-light mr-1 btn btn-outline-primary pt-1 h-100 mt-3 font-weight-bold"
                  to="/enterprise/dashboard"
                >
                  Go to Dashboard
                </Link>
              )}

              <ProfileMenu />
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    selectedGroup: state.auth.selectedGroup,
    selectedGroupVal: state.auth.selectedGroupVal,
    user: state.auth.user,
    globalGroup: state.globalGroup,
  };
};

export default connect(mapStateToProps)(Header);
