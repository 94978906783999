import Http from "./../../../utils/Http";

const apiBase = "/api/v1/enterprise/contentlibrary/";

export function addVideoInContentLibraryRequest(params) {
  return Http.post(apiBase + "add-video-content-task", params);
}

export function getContentLibraryCategoryList(params) {
  let url = apiBase + "get-list-of-video-category";
  return Http.get(url);
}

export function getContentLibraryList(params) {
  let url = apiBase + "get-video-content";
  let {
    contenttype = "",
    search = "",
    catSearch = "",
    sortBy = "latest",
  } = params;
  let _getParams = [];
  if (contenttype != "") {
    _getParams.push(`contenttype=${contenttype}`);
  }
  _getParams.push(`search=${search}`);
  _getParams.push(`catSearch=${catSearch}`);
  _getParams.push(`sortBy=${sortBy}`);

  _getParams.push(`viewallemotional=${params.viewallemotional}`);
  _getParams.push(`viewallcommunication=${params.viewallcommunication}`);
  _getParams.push(`viewallsocial=${params.viewallsocial}`);
  _getParams.push(`viewallinterview=${params.viewallinterview}`);
  url = url + "?" + _getParams.join("&");
  return Http.get(url);
}

export function doAction(params) {
  return Http.post("/api/v1/enterprise/contentactions/do-action", params);
}

export function submitContentReview(params) {
  return Http.post(
    "/api/v1/enterprise/contentactions/submit-content-review",
    params
  );
}

export function assignContentToUsers(params) {
  return Http.post(apiBase + "assign-content-users", params);
}

export function assignContentToGroupUsers(params) {
  return Http.post(apiBase + "assign-content-group-users", params);
}

export function getAssignedMembersListForContent(params) {
  return Http.post(apiBase + "get-members-by-contentid", params);
}
